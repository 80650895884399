import {ICartControllerApi, ICartItem, SettingsOverrides, VeloInputs} from '../../types/app.types';
import {CartService} from '../services/CartService';
import {ProductsService} from '../services/ProductsService';
import {BIService} from '../services/BIService';
import {FedopsInteractions} from '../../common/constants';
import {StyleSettingsService} from '../services/StyleSettingsService';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {SPECS} from '../specs';
import {MinimumOrderAmountService} from '../services/MinimumOrderAmountService';
import {calcTopThreeViolations} from '../utils/violations.utils';

export class CartStore {
  private readonly biService: BIService;
  private readonly cartService: CartService;
  private readonly productsService: ProductsService;
  private readonly controllerApi: ICartControllerApi;
  private readonly styleSettingsService: StyleSettingsService;
  private readonly minimumOrderAmountService: MinimumOrderAmountService;
  private screenReaderMessage: string;
  private settingsOverrides: SettingsOverrides;
  private loadingItems: number[] = [];

  constructor(
    controllerApi: ICartControllerApi,
    private readonly siteStore: SiteStore,
    {
      biService,
      cartService,
      productsService,
      styleSettingsService,
      minimumOrderAmountService,
    }: {
      biService: BIService;
      cartService: CartService;
      productsService: ProductsService;
      styleSettingsService: StyleSettingsService;
      minimumOrderAmountService: MinimumOrderAmountService;
    },
    veloInputs: VeloInputs
  ) {
    this.setOverrides(veloInputs);
    this.controllerApi = controllerApi;
    this.biService = biService;
    this.cartService = cartService;
    this.productsService = productsService;
    this.styleSettingsService = styleSettingsService;
    this.minimumOrderAmountService = minimumOrderAmountService;
  }

  public clearLoadingItems = () => {
    this.loadingItems = [];
  };

  private readonly clearError = async () => {
    this.cartService.setHasErrorState(false);
    await this.controllerApi.updateComponent(false);
  };

  private readonly updateItemQuantity = async (cartItemId: number, quantity: number, productId: string) => {
    this.loadingItems.push(cartItemId);
    await this.controllerApi.updateComponent(true);
    try {
      return await this.controllerApi.reportFedopsInteraction(FedopsInteractions.UpdateQuantityInCart, () =>
        this.cartService.updateItemQuantity(cartItemId, quantity, productId)
      );
    } catch (e) {
      /* istanbul ignore next */
      if (!this.siteStore.experiments.enabled(SPECS.HandleCartError)) {
        throw e;
      }
      await this.catchServerError(e as Error);
    }
  };

  private readonly removeItemFromCart = async (item: ICartItem) => {
    this.loadingItems.push(item.cartItemId);
    await this.controllerApi.updateComponent(true);
    this.screenReaderMessage = this.controllerApi.t('cart.sr_item_was_removed', {item_name: item.product.name});
    try {
      return await this.controllerApi.reportFedopsInteraction(FedopsInteractions.RemoveItemFromCart, () =>
        this.cartService.removeItemFromCart(item)
      );
    } catch (e) {
      /* istanbul ignore next */
      if (!this.siteStore.experiments.enabled(SPECS.HandleCartError)) {
        throw e;
      }
      await this.catchServerError(e as Error);
    }
  };

  private readonly catchServerError = async (error: Error) => {
    this.clearLoadingItems();
    this.cartService.setHasErrorState(true);
    await this.controllerApi.updateComponent(false);
    this.sendErrorPresentedInCartSideCart(error.message);
    setTimeout(() => {
      void (async () => {
        await this.clearError();
      })();
    }, 5000);
  };

  private readonly applyCoupon = async (code: string) => {
    this.cartService.clearCouponError();
    await this.controllerApi.updateComponent();

    await this.controllerApi.reportFedopsInteraction(FedopsInteractions.ApplyCouponInOrder, () =>
      this.cartService.applyCoupon(code).catch(() => this.controllerApi.updateComponent())
    );
  };

  private readonly removeCoupon = () => {
    return this.controllerApi.reportFedopsInteraction(FedopsInteractions.ApplyCouponInOrder, () =>
      this.cartService.removeCoupon()
    );
  };

  private readonly sendToggleCouponBi = () => {
    return this.biService.clickOnApplyPromotionalCodeSf(this.cart);
  };

  private readonly sendAddNoteBi = () => {
    return this.biService.clickOnAddNoteToSellerSf(this.cart);
  };

  private readonly sendMinimumOrderMessageShownBi = (buttonEnabled: boolean) => {
    return this.biService.minimumOrderMessageIsShownInCart(this.cart, buttonEnabled);
  };

  private readonly sendErrorPresentedInCartSideCart = (errorMessage: string) => {
    return this.biService.errorPresentedInCartSideCart(errorMessage);
  };

  private readonly updateBuyerNote = (...args: Parameters<CartService['updateBuyerNote']>) => {
    return this.cartService.updateBuyerNote(...args);
  };

  private get cart() {
    return this.cartService.cart;
  }

  private readonly getProductsManifest = () => {
    return this.productsService.manifest(this.cartService.cart);
  };

  private get isCartValid(): boolean {
    return this.cartService.areAllItemsInStock;
  }

  private get isPartiallyPaid(): boolean {
    return this.cart?.totals?.payLater > 0;
  }

  private get shouldDisplayViolations(): boolean {
    return this.cartService.cart?.violations?.length > 0;
  }

  public setOverrides(veloInputs: VeloInputs) {
    this.settingsOverrides = {
      ...this.settingsOverrides,
      ...veloInputs,
    };
  }

  public async toProps() {
    const experimentProps = {
      shouldPresentTooltipWithoutNumber: this.siteStore.experiments.enabled(SPECS.CartTooltipWithoutNumber),
      shouldUseChevronForItemOptions: this.siteStore.experiments.enabled(SPECS.UseChevronForItemOptions),
      makeCartItemNameLink: this.siteStore.experiments.enabled(SPECS.MakeCartItemNameLink),
      shouldSupportCardTokenizationOnCart: this.siteStore.experiments.enabled(
        SPECS.SupportCardTokenizationOnCartAndCheckout
      ),
      shouldUpdateInvalidCartItemQuantity: this.siteStore.experiments.enabled(SPECS.InvalidCartItemQuantity),
      shouldUpdateCartItemMoreOptionsFont: this.siteStore.experiments.enabled(SPECS.CartItemMoreOptionsFont),
      shouldFixCartItemOptionsBug: this.siteStore.experiments.enabled(SPECS.FixCartItemOptionsBug),
      shouldShowCartError: this.siteStore.experiments.enabled(SPECS.HandleCartError),
      shouldShowErrorMessage: this.siteStore.experiments.enabled(SPECS.ErrorMessage),
    };

    if (this.cartService.isEmpty) {
      return {
        shouldRenderEmptyState: true,
        ...experimentProps,
      } as any;
    }

    return {
      loadingItems: this.loadingItems,
      cart: this.cart,
      itemsCount: this.cart?.items?.reduce((count, item) => count + item.quantity, 0),
      isCardTokenizationCart: this.cart?.items?.some((item) => item.savePaymentMethod),
      isNonShippableCart: this.cartService.isNonShippableCart,
      manifest: await this.getProductsManifest(),
      applyCoupon: this.applyCoupon,
      removeCoupon: this.removeCoupon,
      couponError: this.cartService.couponError,
      isFreeCart: this.cartService.isZeroCart,
      sendToggleCouponBi: this.sendToggleCouponBi,
      removeItemFromCart: this.removeItemFromCart,
      sendAddNoteBi: this.sendAddNoteBi,
      sendMinimumOrderMessageShownBi: this.sendMinimumOrderMessageShownBi,
      updateBuyerNote: this.updateBuyerNote,
      updateItemQuantity: this.updateItemQuantity,
      screenReaderMessage: this.screenReaderMessage,
      isCartValid: this.isCartValid,
      shouldShowCoupon: this.styleSettingsService.shouldShowCoupon,
      shouldShowBuyerNote: this.styleSettingsService.shouldShowBuyerNote,
      shouldShowExpressCheckout: this.styleSettingsService.shouldShowExpressCheckout,
      shouldShowMinimumOrderAmount: this.minimumOrderAmountService.shouldDisplayNotification,
      shouldShowTopCheckoutButtonInMobile: this.styleSettingsService.shouldShowTopCheckoutButtonInMobile,
      shouldDisplayViolations: this.shouldDisplayViolations,
      shouldShowSecureCheckout: this.settingsOverrides.shouldShowSecureCheckout,
      isPartiallyPaid: this.isPartiallyPaid,
      hasErrorViolations: this.cartService.hasErrorViolations,
      topThreeViolations: calcTopThreeViolations(this.cartService.cart?.violations),
      ...experimentProps,
      shouldShowError: this.cartService.hasError && this.siteStore.experiments.enabled(SPECS.HandleCartError),
      clearError: this.clearError,
    };
  }
}
